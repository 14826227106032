import { faBars, faBarsStaggered, faLocationPinLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import MainIcon  from '../images/GoldBox/logo-ar-removebg-2.png';
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

export const LongNavBar = ()=>
{
    const {i18n,t} = useTranslation(["NavBar"]);

    useEffect(()=>{

        if(localStorage.getItem("i18nextLng")?.length>2)
        {
            i18next.changeLanguage("en");
        }


    },[]);

    const handleLanguageChange = (e)=>
    {
            i18n.changeLanguage(e.target.value);

    }

    return(
        <div className=" w-full h-fit absolute top-0 hidden lg:flex p-2">

            
            <div className="flex flex-row place-items-center justify-end w-full">
            <Link className="text-white" to="ConnectUs"  smooth={true}><button className="btn btn-ghost btn-sm " >{t("contactus")}</button></Link>
                <Link className="text-white" to="AboutUs"  smooth={true}><button className="btn btn-ghost btn-sm " >{t("features")}</button></Link>
                <Link className="text-white" to="Services"  smooth={true}><button className="btn btn-ghost btn-sm " >الفوائد</button></Link>
                <Link className="text-white" to="Home"  smooth={true}><button className="btn btn-ghost btn-sm " >الرئيسية</button></Link>

            </div>
        </div>
    );
}


export const ShortNavBar = ()=>
{
    const [ToggleDrawer, setToggleDrawer] = useState(false);

    const {i18n,t} = useTranslation();

    useEffect(()=>{

        if(localStorage.getItem("i18nextLng")?.length>2)
        {
            i18next.changeLanguage("en");
        }

     
    },[]);

    const handleLanguageChange = (e)=>
    {
            i18n.changeLanguage(e.target.value);

    }

    const handleClick = ()=>
    {
        console.log("test");
    }

    const SideDrawer = () =>
    {
        return(

           <div className={` ${ToggleDrawer?"flex":"hidden"} flex flex w-32 bg-white h-[400px]  shadow-md absolute top-0 right-0 z-50 rounded-md `}>

            </div>
        );
    }

    return(
        <div className="flex  w-full h-fit absolute top-0   md:visible lg:invisible ">
            
            
            <div className="flex flex-row place-items-center justify-start  pl-4   w-1/2 pt-2">
               
            </div>


            
            <div className="flex flex-row place-items-center justify-end w-1/2 ">

            </div>

        </div>
    );
}