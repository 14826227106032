import { faBars, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Aos from "aos";
import { useTranslation } from "react-i18next";
import CoverImage from '../images/GoldBox/CoverSquare2.jpeg';
import SquareImage1 from '../images/GoldBox/square1.jpeg';
import SquareImage2 from '../images/GoldBox/square2.jpeg';
import SquareImage3 from '../images/GoldBox/square3.jpeg';


const Services = ()=>
{
    const PurpleColor = "#9A549F";
    const {t} = useTranslation(["Services"]);

    useEffect(()=>{

        Aos.init();

        } ,[]);

    return (

        <div className="flex h-fit  md:h-screen place-items-center  w-screen md:w-full  bg-white  " style={{backgroundImage:`url(${CoverImage})`,backgroundSize:'cover'}}>


            <div className="flex flex-col p-1 md:flex-row gap-7 w-full justify-center place-items-center mt-10 h-fit md:h-32">

                <img src={SquareImage1} className="w-[250px] h-[250px] rounded-xl shadow-xl border border-2 border-black" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="1000"/>
                <img src={SquareImage2} className="w-[250px] h-[250px] rounded-xl shadow-xl border border-2 border-black" data-aos="zoom-in" data-aos-delay="200" data-aos-duration="1000"/>
                <img src={SquareImage3} className="w-[250px] h-[250px] rounded-xl shadow-xl border border-2 border-black" data-aos="zoom-out" data-aos-delay="200" data-aos-duration="1000"/>

            </div>

        </div>
    );
}

export default Services;