import { faBitbucket, faBitcoin, faFacebookF, faInstagram, faLinkedin, faTiktok } from "@fortawesome/free-brands-svg-icons";
import { faBank, faCoins, faCreditCard, faEnvelope, faLink, faLocation, faLocationPin, faMinus, faPhone, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import axios from "axios";
import $, { ajaxPrefilter } from 'jquery';
import { useTranslation } from "react-i18next";
import Aos from "aos";
import { GetUrlPath, getCurrentDate, getCurrentTime } from "../Helpers/helper";


const ConnectUs = () =>
{
    const [Name,setName] = useState("");
    const [PhoneNumber,setPhoneNumber] = useState("");
    const [Email,setEmail] = useState("");
    const [Zone,setZone] = useState("");
    const {t} = useTranslation(["ContactUs"]);
    const Lng = localStorage.getItem("i18nextLng");
    const [isLoadin,setisLoading] = useState(false);

    useEffect(()=>{

        Aos.init();
        console.log(getCurrentTime());

        } ,[]);


    const handleSubmitClick = ()=>
    {
        if(Name !='' && PhoneNumber !='' && Email !='' && Zone !='')
        {
            setisLoading(true);
            let lead = {};
            lead.name = Name;
            lead.phone_number = PhoneNumber;
            lead.email = Email;
            lead.zone = Zone;
            lead.link = window.location.href;

            let data = {};
            data.mode = "insert";
            data.query = "lead";
            let params = {};
            params.date = getCurrentDate('us');
            params.status = "new";
            params.campaign = "GoldBox";
            params.time = getCurrentTime();
            params.bus_id = 1;
            params.data=  JSON.stringify(lead);
            data.params= params;


            axios.post(GetUrlPath(),data)
            .then(res=> {
               
                
                alert("lead has ben sent!");
                setName('');
                setPhoneNumber('');
                setEmail('');
                setZone('');
                setisLoading(false)

            })

            .catch(Error => {
                console.log(Error);
            });


        

        }
        else
        {
            alert("All feilds are required!!");
        }

    }


    return( 

        <div className="flex flex-col gap-2 bg-neutral place-items-center justify-center w-full p-4 ">

        <div className="flex  mt-1 mb-3 mr-3">
        <label className="text-white text-3xl font-bold text-goldbox" >--</label>
            <label className="text-white text-3xl font-bold" style={{color:"white"}}>تواصل معنا</label>
            <label className="text-white text-3xl font-bold text-goldbox" >--</label>
        </div>

        <div className="flex flex-col mb-4">

         </div>


         <div  className="grid md:grid-cols-2 gap-2 " style={{direction:Lng =="en"?"ltr":"rtl"}}>

            {/*Left Side panel*/}
            <div className="flex flex-col gap-6 place-items-center justify-center">

            {/*  */}
            <div className="flex flex-col gap-1">
            <div className="flex flex-col w-fit">
            <p className="font-semibold text-white text-xl">تواصل معنا</p>
            <div className=" h-0 outline-1 outline text-goldbox" ></div>
            </div>

            <p className="font-md text-white w-2/3">
            عزيزي العميل، يسعدنا تواصلك معنا الرجاء ترك بيانتك  وسنقوم بالرد عليك في أقرب وقت ممكن
            </p>



            </div>


            <div className="flex flex-row w-full gap-6 mt-6">
            <FontAwesomeIcon  icon={faBank} size="4x" ></FontAwesomeIcon >
            <FontAwesomeIcon  icon={faCreditCard} size="4x"></FontAwesomeIcon>
            <FontAwesomeIcon  icon={faCoins} size="4x"></FontAwesomeIcon>
            <FontAwesomeIcon  icon={faBitcoin} size="4x"></FontAwesomeIcon>
                 </div>


            </div>

            {/*Right Side panel*/}


            <div className="flex flex-col place-items-center pb-5">


            {/* form */}
            <div className="flex flex-col mt-6 gap-3 w-2/3">

            <div className="flex flex-col ">
            <label className="text-white text-center">الاسم</label>
            <input type="text" placeholder="" className="input input-sm w-full text-white bg-goldbox "  value={Name} onChange={(e)=>setName(e.target.value)} />
            </div>

            <div className="flex flex-col">
            <label className="text-white text-center">رقم الجوال</label>
            <input type="number" inputMode="numeric" placeholder="" className="input input-sm w-full text-white bg-goldbox"  value={PhoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} />
            </div>


            <div className="flex flex-col">
            <label className="text-white text-center">البريد الالكتروني</label>
            <input type="email" placeholder="" className="input input-sm w-full text-white bg-goldbox "  value={Email} onChange={(e)=>setEmail(e.target.value)}/>
            </div>

            <div className="flex flex-col">
            <label className="text-white text-center">المنطقة</label>
            <input type="text" placeholder="" className="input input-sm w-full text-white bg-goldbox "  value={Zone} onChange={(e)=>setZone(e.target.value)}/>
            </div>

            {
                isLoadin?
                <button className="btn btn-ghost btn-sm text-white rounded-full outline-1 outline-dashed outline-white mt-4"  ><FontAwesomeIcon icon={faSpinner} color="white" className="animate-spin" /></button>
                :
                <button className="btn btn-ghost btn-sm text-white rounded-full outline-1 outline-dashed outline-white mt-4"  onClick={handleSubmitClick}>متابعة</button>
            }


            </div>

            </div>

         </div>

        </div>
    );
}

export default ConnectUs;