import logo from './logo.svg';
import './App.css';
import { LongNavBar } from './components/TopNavBar';
import Home from './components/Home';
import AboutUs from './components/Aboutus';
import Services from './components/Services';
import ConnectUs from './components/ConnectUs';

import { Element ,Link, ScrollLink} from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';



function App() {



  return (
    <div className='flex flex-col w-full h-fit relative overflow-x-none'>

      <Element name="Home">
      <Home/>
      </Element>


      <Element name='ConnectUs'>
      <ConnectUs />
      </Element>


      <Element name="Services">
      <Services />
      </Element>


      <div className="grid grid-cols-2 w-full h-20 place-items-center justify-center p-1 bg-goldbox " >
      <label className="text-white font-semibold text-center"> هل انت جاهز لبدء مشروعك؟  </label>
      <div className="w-fit h-fit">
          <Link to="ConnectUs" smooth={true} className="h-fit w-fit">
          <button className="btn btn-ghost outline-1 outline-dashed  btn-sm pl-3 pr-3  outline-white text-white">انطلق الان</button>
          </Link>
      </div>
      </div>
      

      <Link className="text-white  bottom-9 right-4 fixed " to="Home"  smooth={true}>
        <FontAwesomeIcon 
         className='p-3 rounded-full shadow-2xl bg-goldbox' 
         icon={faChevronUp} size='xl' 
         />
        </Link>


      <div className='flex w-full h-10 place-items-center justify-center text-center'>
      حقوق الطبع والنشر © 2024 |  كل الحقوق محفوظة
      </div>

    </div>
  );
}

export default App;
