import React, { useEffect, useState } from "react";
import { LongNavBar, ShortNavBar } from "./TopNavBar";
import CoverImage from  '../images/GoldBox/Cover5.jpeg';
import { Link } from "react-scroll";
import MainVideo from '../videos/mainvid.mp4'

 const Home = ()=>
{


    return(
        <div className='flex flex-col md:h-screen w- relative '>
            <div className="flex h-[280px] bg-transparent w-full  md:mt-[50px] md:h-[650px] rounded-md">
                <div className="bg-white w-full rounded-lg shadow-lg z-50" >
                    <video  autoPlay={true} muted src={MainVideo} controls className="w-full h-full" />
                </div>

            </div>
        <LongNavBar  />
        <ShortNavBar/>

        <div className="flex flex-col  absolute w-full h-full">
            <div className="flex w-full h-1/2">

            </div>

            <div className="flex w-full h-1/2">

            <div className="flex w-full md:h-1/3 justify-center">
            <Link className="text-white"  to="ConnectUs"  smooth={true}> <button className="btn md:btn-wide btn-xs  btn-sm md:btn-md md:bg-xl bg-white text-sm  md:text-2xl outline outline-1 outline-goldbox  text-goldbox font-bold" style={{fontFamily:"cursive"}}>صندوق الأستثمارات</button></Link>
            </div>

            </div>


        </div>

       
        </div>
    );
}

export default Home;