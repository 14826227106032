import axios from "axios";
import { ENV, PaypalClientId, PaypalEnv, ResetPassUrl, UrlPath } from "./config";
import { format } from "date-fns";

const { faMobileAndroid } = require("@fortawesome/free-solid-svg-icons");

const env = ENV.PROD;
const EnvPaypal = PaypalEnv.LIVE;

function GetUrlPath()
{
  const Path = UrlPath(env);

  return Path;
}

function GetResetPassUrl()
{
  let resetUrl =  ResetPassUrl(env);

  return resetUrl;
}

function GetPaypalClienId()
{
  let clientid = PaypalClientId(EnvPaypal);

  return clientid;
}

function generateDateList(numDays){
    const currentDate = new Date();
    const dateList = [];

    for (let i = 0; i < numDays; i++) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() + i);
      const day = date.toLocaleDateString('en-GB', { weekday: 'short' });
      const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });
      const dayName = day.substr(0, 3);
      dateList.push({ date: formattedDate, dayName });
    }

    return dateList;
  };


  function getDayName()
  {
    const currentDate = new Date();

    const date = new Date(currentDate);
    date.setDate(date.getDate());
    const day = date.toLocaleDateString('en-GB', { weekday: 'short' });
    const dayName = day.substr(0, 3);

    return dayName;
  }


  function isTimeInRange(time, startTime, endTime) {
    const [timeHour, timeMinute] = time.split(':').map(Number);
    const [startHour, startMinute] = startTime.split(':').map(Number);
    const [endHour, endMinute] = endTime.split(':').map(Number);

    if (timeHour > startHour || (timeHour === startHour && timeMinute >= startMinute)) {
        if (timeHour < endHour || (timeHour === endHour && timeMinute < endMinute)) {
            return true;
        }
    }
    return false;
}

  function generateTimes(startTime, endTime, duration,next_queue){
    let times = [];

    // Parse the start and end times
    const startDate = new Date(`2000/01/01 ${startTime}`);
    const endDate = new Date(`2000/01/01 ${endTime}`);
    
    // Check if the end time is on the next day
    const nextDayEndTime = new Date(`2000/01/02 ${endTime}`);
    const durationMs = duration * 60000; // Convert minutes to milliseconds
    
    // Iterate from the start time to the end time
    let currentTime = startDate;
    while ((endTime >= startTime && currentTime <= endDate) ) {
      times.push(currentTime.toLocaleTimeString('en-US', {hour12: false, hour: '2-digit', minute: '2-digit'}));
      currentTime = new Date(currentTime.getTime() + durationMs);
    }


    if(next_queue != null)
    {
      let hour = times[times.length-1];
      //console.log(hour);
      //console.log(next_queue);
      const queue = new Date(`2000/01/01 ${next_queue}`);
      const endDate = new Date(`2000/01/01 ${hour}`);
      let res =  queue.getMinutes() - endDate.getMinutes() < duration ? true:false;
      //console.log("------");
      if(res)
      times = times.filter((time)=> time !== hour );
    }


    return times;
  }


  function addMinutesToHour(currentTime, minutesToAdd) {
    const currentTimeObj = new Date(`1970-01-01T${currentTime}`);
    currentTimeObj.setMinutes(currentTimeObj.getMinutes() + minutesToAdd);
    return currentTimeObj.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
  }

  function getCurrentTime() {
    const currentDate = new Date();

    const currentHour = currentDate.getHours().toString().padStart(2, '0');
    const currentMinute = currentDate.getMinutes().toString().padStart(2, '0');
    console.log(currentHour + ":" + currentMinute)
    return currentHour + ":" + currentMinute;

}

  function getCurrentTimeDate()
  {
    const currentDate = new Date();

    return currentDate;
  }


function subtractMinutes(currentTime, minutesToSubtract) {
  const currentTimeObj = new Date(`1970-01-01T${currentTime}`);
  currentTimeObj.setMinutes(currentTimeObj.getMinutes() - minutesToSubtract);
  return currentTimeObj.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });
}


  function sortArrayByHour(array) {
    return [...array].sort((a, b) => {
      const timeA = new Date(`2023-08-14 ${a.start_time}`);
      const timeB = new Date(`2023-08-14 ${b.start_time}`);
      
      return timeA - timeB;
    });
  }


  function compareHoursWithdate(src, cmp,cmpDate) {

    const [cmpDay,cmpMonth] = cmpDate[1].date.split("/");
    cmpDate = new Date().getFullYear()+"-"+cmpMonth+"-"+cmpDay;

    const srcHours = src;
    const cmpHours = new Date(`${cmpDate} ${cmp}`);
    //console.log(srcHours);

    if(srcHours > cmpHours)
    return 1;
    else 
    if(srcHours < cmpHours)
    return -1;
    else
    return 0;

  }


  function compareHours(src, cmp) {
    const [srcHour, srcMinute] = src.split(':').map(Number);
    const [cmpHour, cmpMinute] = cmp.split(':').map(Number);

    if (srcHour > cmpHour || (srcHour === cmpHour && srcMinute > cmpMinute)) {
        return 1;
    } else if (srcHour < cmpHour || (srcHour === cmpHour && srcMinute < cmpMinute)) {
        return -1;
    } else {
        return 0;
    }
}


 function roundMinutes(time)
 {
    let CurrentDate = new Date();
    let [hour,minutes]  = time.split(":");
    let digit = 0 ;

    CurrentDate.setHours(parseInt(hour));
    minutes = parseInt(minutes) + 10;
    CurrentDate.setMinutes(minutes);

    minutes =  CurrentDate.getMinutes() % 10 ;


    if(minutes >= 5)
      digit = 10-minutes;
    else
      if(minutes !=0)
      digit = 5-minutes;

    CurrentDate.setMinutes(CurrentDate.getMinutes() + digit);

    return CurrentDate.getHours() + ":" + CurrentDate.getMinutes();
    
 }

  function roundMinutes2(time) {
    const [hours, minutes] = time.split(':');
  
    let roundedMinutes;
    let roundedHours = hours;
    
    if (parseInt(minutes) >= 55 || parseInt(minutes) % 5 ==0) {

        if(parseInt(minutes) >=55)
        {
          roundedMinutes = '05';
          roundedHours = String(Number(hours) + 1).padStart(2, '0');
        }
        else
        {
          roundedMinutes = parseInt(minutes) + 10;
        }


    } else {
      roundedMinutes = Math.round(parseInt(minutes) / 5) * 5 +5 ;

      if(parseInt(roundedMinutes) >=55)
      {
        roundedMinutes = '05';
        roundedHours = String(Number(hours) + 1).padStart(2, '0');
      }

    }
    
    if (roundedHours === '24') {
      roundedHours = '00';
    }
    
    let roundedTime = roundedHours + ':' + roundedMinutes.toString().padStart(2, '0');
    return roundedTime;
  }


  function getCurrentDate2Digits(currentDate)
  { 
    if(currentDate == null)
    currentDate = new Date();

    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    return day +"/" +month;
  }

  const compressImage = async (file,Dimension,Quality) => {

    if(Dimension == null || Quality == null)
    {
      Dimension = 800;
      Quality = 0.7
    }


    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxDimension = Dimension; // Adjust this value as needed
          const aspectRatio = img.width / img.height;

          let newWidth = maxDimension;
          let newHeight = maxDimension / aspectRatio;

          if (aspectRatio < 1) {
            newWidth = maxDimension * aspectRatio;
            newHeight = maxDimension;
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            resolve(compressedFile);
          }, 'image/jpeg', Quality); // Adjust the quality as needed
        };
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  function compressBase64Image(base64Image, Dimension, quality) {

    if(Dimension == null || quality == null)
    {
      Dimension = 800;
      quality = 0.7
    }

    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Image;
  
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const originalWidth = img.width;
        const originalHeight = img.height;
  
        // Calculate the new height based on the maxWidth while maintaining the aspect ratio
        const newWidth = Dimension;
        const newHeight = (originalHeight / originalWidth) * newWidth;
  
        canvas.width = newWidth;
        canvas.height = newHeight;
  
        // Draw the image on the canvas with the new dimensions
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
  
        // Convert the canvas content back to base64
        const compressedBase64 = canvas.toDataURL('image/jpeg', quality);
  
        resolve(compressedBase64);
      };
  
      img.onerror = (error) => {
        reject(error);
      };
    });
  }
  
  
  

  function encrypt64(target)
{
    return window.btoa(target);
}

function decrypt64(target)
{
    
    return window.atob(target);
}

function encrypt(target)
{
    const CryptoJS =require("crypto-js");
    const  secret = "J.S2+$5f~w(#Bj;+";
    const iv= '0123456789ABCDEF';

    return CryptoJS.AES.encrypt(target,secret,{iv:iv}).toString();
}


function decrypt(target)
{
    const CryptoJS =require("crypto-js");
    const  secret = "J.S2+$5f~w(#Bj;+";
    const iv= '0123456789ABCDEF';
    
    return CryptoJS.AES.decrypt(target,secret,{iv:iv}).toString(CryptoJS.enc.Utf8);
} 

function formatTime(minutes) {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (remainingMinutes === 0) {
      return `${hours}h`;
    } else {
      return `${hours}h ${remainingMinutes}m`;
    }
  } else {
    return `${minutes}m`;
  }
}


async function  isValidForAtob (str)  {
  try {
    // Attempt to decode the string using atob
    const decoded = atob(str);

    // If decoding is successful and the result can be encoded back to the original string, it's valid
    return true;
  } catch (error) {
    // If decoding or encoding fails, the string is not valid
    return false;
  }
}

const SendSms = async (type,data2,Phonenumber)=>
{
    let phone_number = "+972"+Phonenumber.substring(1);
    //console.log(phone_number);

    let data = {};
    data.mode = type;
    let params = {};
    params.numberPhone = phone_number;
    params.data = type == "SMSAddOrder"?JSON.stringify(data2):"";
    data.params = params;


      let res = await axios.post(GetUrlPath(),data); 

      if(type == "VerfCode")
      return res.data;

}

function DateFormat(options)
{
  switch(options)
  {
    case "eu":
      return "dd/MM/yyyy";
    case "us":
      return "yyyy-MM-dd";
    default :
    return "dd/MM/yyyy";
  }
}


function getCurrentDate(options)
{
  const currentDate = new Date();

  switch(options)
  {
    case "eu":
        return format(currentDate,DateFormat("eu"));
        case "us":
          return format(currentDate,DateFormat("us"));
          default : 
          return format(currentDate,DateFormat("eu"));  
  }

}


function ConvertDate(date,from,to)
{
  let day= "";
  let month = "";
  let year = "";

    switch(from)
    {
       case 'eu':
        {
          if(date.includes("/"))
          [day,month,year] = date.split('/');
          break;
        }
        case 'us':
          {
            if(date.includes("/"))
            [month,day,year] = date.split('/');
            else
            [year,month,day] = date.split('-');
            break;
          }

    }


  switch(to)
  {
    case "eu":
      return day+"/"+month+"/"+year;
      case "us":
        return year+"-"+month+"-"+day;
        default:
        return day+"/"+month+"/"+year;
  }
    

}

function checkStandalone()
{
  const isInWebAppiOS = window.navigator.standalone || (window.matchMedia('(display-mode: standalone)').matches);
  const isInWebAppAndroid = window.matchMedia('(display-mode: standalone)').matches;

  return  isInWebAppAndroid || isInWebAppiOS;
}

  export {compressBase64Image}
  export {checkStandalone};
  export {GetPaypalClienId};
  export {getCurrentDate};
  export {ConvertDate};
  export {DateFormat};
  export {SendSms};
  export {encrypt};
  export {decrypt};
  export {isValidForAtob};
  export {GetResetPassUrl};
  export {formatTime};
  export {generateDateList};
  export {generateTimes};
  export {addMinutesToHour};
  export {sortArrayByHour};
  export {subtractMinutes};
  export {isTimeInRange};
  export {getCurrentTime};
  export {compareHours};
  export {roundMinutes};
  export {getCurrentDate2Digits};
  export {GetUrlPath};
  export {getDayName};
  export {compressImage};
  export {encrypt64};
  export {decrypt64};  
  export {getCurrentTimeDate};
  export {compareHoursWithdate};