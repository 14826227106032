
const ENV = {
    DEV : "dev",
    PROD : "prod",
    TEST : "test"
}

const PaypalEnv = {
    SANDBOX : "sandbox",
    LIVE : "live"
}

const UrlPath = (env)=>
{
    switch (env) {
        
        case ENV.DEV:
            {
                return "http://localhost/uqueue/public/php/index.php";
            }
            case ENV.TEST:
                {
                    return "https://mockalt.com/goldbox/php/index.php?t="+ new Date().getTime();
                }
                case ENV.PROD:
                    {
                        return "https://mockalt.com/goldbox/php/index.php?t="+ new Date().getTime();
                    }

    }
}

const QrCodeUrl = (env)=>
{
    switch (env) {
        
        case ENV.DEV:
            {
                return "http://localhost/uqueue#/BusinessProfile?";
            }
            case ENV.TEST:
                {
                    return "https://mockalt.com/uqueue/#/BusinessProfile?";
                }
                case ENV.PROD:
                    {
                        return "https://u-queue.net/uqueue/#/BusinessProfile?";
                    }

    }
}

const ResetPassUrl = (env)=>
{   

    switch (env) {
        
        case ENV.DEV:
            {
                return "http://localhost/uqueue/#/ResetPasswordPage";
            }
            case ENV.TEST:
                {
                    return "https://mockalt.com/uqueue/#/ResetPasswordPage";
                }
                case ENV.PROD:
                    {
                        return "https://u-queue.net/uqueue/#/ResetPasswordPage";
                    }

    }
}


const PaypalClientId = (env)=>
{
    switch (env) {
        
        case PaypalEnv.SANDBOX:
            {
                return "ELo41SFFBKZkdTDeOYpO-g54CzmplnDH9uNhsf32Pl2goboS1F0FPshXWZJO-qV7yqmGvQwFXeW_Zvd9";
            }
            case PaypalEnv.LIVE:
                {
                    return "Afl392MbHiDZDcEk0Wfjr_XeBgwQLcaE597GgDN9hAFjjGNUHMAdT68bUUentE9gDWXZFPgCyQ31rQ4U";
                }

        }
}



export {ResetPassUrl};
export {ENV};
export {PaypalEnv}
export {UrlPath};
export {QrCodeUrl};
export {PaypalClientId};